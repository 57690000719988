import { fetchConToken, fetchSinToken} from '../../fetch'
const authRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Trae las empresas del Usuario
         const resp1 = await fetchSinToken("authPlanilla/companiesuser", parameters, "POST")
         const body1 = await resp1.json()
         return body1     
         break 
      case 2:  // Hay que colocar el llamado del usuario
         const resp2 = await fetchSinToken('authPlanilla', parameters, "POST")
         const body2 = await resp2.json()
         return body2
         break      
      case 3:  // Trae la lista del menú del empleado
         const resp3 = await fetchConToken("authPlanilla/systemmenu", parameters, "POST")
         const body3 = await resp3.json()
         return body3     
         break 
      case 4:  // Trae la lista de programas favoritos del usuario BookMarks
         const resp4 = await fetchConToken("authPlanilla/bookmark", parameters, "POST")
         const body4 = await resp4.json()
         return body4    
         break    
      case 5:  // Agrega o elimina BookMarks
         const resp5 = await fetchConToken("authPlanilla/deleteaddbookmark", parameters, "PUT")
         const body5 = await resp5.json()
         return body5     
         break   
      case 6:  // Recupera la clave
         const resp6 = await fetchSinToken("authPlanilla/recuperarclave", parameters, "PUT")
         const body6 = await resp6.json()
         return body6     
         break       
      case 7:  // Cambia la Contraseña
         const resp7 = await fetchConToken("authPlanilla/change", parameters, "PUT")
         const body7 = await resp7.json()
         return body7     
         break   
      case 8:  // Trae los datos del usuario conectado
         const resp8 = await fetchConToken("authPlanilla/userdatalogin", parameters, "POST")
         const body8 = await resp8.json()
         return body8     
         break  
      case 9: // Renovar el Token
         const resp9 = await fetchConToken("authPlanilla/renew")
         const body9 = await resp9.json()
         return body9 
         break                   
      }      
   }
   export default authRequest