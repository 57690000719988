export default {
    titleState: "EMPLOYEE STATE",
    total: 1,
    allData: [],
    allEntry: [],
    allLocalization: [],
    allSalary: [],
    data: [],
    params: {},
    selectedEmployee: {},
    selectedEmployeeLocalization: [],
    selectedEmployeeSalary: [],
    selectedEmployeeEntry: [],
    generalEmployee: {},
    localizationsEmployee: [],
    workEmployee: {},
    paymentIndicator: '',
    salaryEmployee: {},
    proccess: null,
    checking: true
}

