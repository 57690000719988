export default {
    titleState: "POST PAID ROLL STATE",
    totalPayrollList: 0,
    allDataPayrollList: [], 
    dataPayrollList: [], 
    selectedPayroll: {},
    totalEmployeeList: 0,
    allDataEmployeeList: [],
    dataEmployeeList: [], 
    selectedEmployee: {},
    allDataDocumentList: [],
    DataDocumentList: []
}

