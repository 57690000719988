// Import Propios
import presenceInicialState from '../initialstates/presenceInicialState'
import { types } from '../../../types/types'


const presence = (state, action) => {
  console.log('action Presence', action.type)
  console.log('action Presence Data', action.data)
    switch (action.type) {
        // Lista de Planillas Proyectadas
        case types.asistenciaListaPlanilla:
          return {
            ...state,
            payrollList: action.value
          } 
        case types.asistenciaEmpleadosPlanilla:
            return {
              ...state,
              allData: action.value
           }          
        case types.asistenciaPlanillaSeleccionada:
          return {
            ...state,
            selectedPayroll: action.value
          } 
        case types.procesoValores:
            return {
              ...state,
              data: action.data,
              total: action.total
            }           
        case types.asistenciaEmpleadoSeleccionado:
           return {
            ...state,
            selectedEmployee: action.value
          }
        case types.asistenciaFechaSeleccionada:
            return {
              ...state,
              selectedDate: action.value
            }
 
        case types.asistenciaHoraEntrada:
             return {
               ...state,
               entryTime: action.value
             }         
        case types.asistenciaHoraSalida:
              return {
                ...state,
                outTime: action.value
              }
        case types.asistenciaLista:
              return {
                ...state,
                presence: action.value
              }  
        case types.asistenciaProyeccionLista:
             return {
               ...state,
               projection: action.value
             }    
        case types.asistenciaRangosFecha:
              return {
                ...state,
                datesRange: action.value
              }  
        case types.asistenciaDiasVacaciones:
             return {
                ...state,
                vacationDaysEmployee: action.value
              }  
        case types.asistenciaPlanillasReloj:
             return {
                ...state,
                clockPayroll: action.value
              }        
        case types.asistenciaEmpleadosReloj:
             return {
                ...state,
                clockEmployee: action.value
              }
        case types.asistenciaStatusSeleccionado:
                return {
                   ...state,
                   selectedStatus: action.data
                 }
        case types.asistenciaTotal:
             return {
               ...state,
               allDataReviewStatus: action.value
             }   
        case types.asistenciaEmpleado:
              return {
                ...state,
                asistenceEmployeeStatus: action.value
              }  
        case types.asistenciaEmpleadoDia:
                return {
                  ...state,
                  dateAsistenceStatus: action.value
                }     
        case types.asistenciaFiltroNombre:
                return {
                    ...state,
                    nameFilter: action.value
                 }   
        case types.asistenciaHorasExtras:
                return {
                    ...state,
                    extraHours: action.value
                 }           
         
      case types.asistenciaLogouts:
          return presenceInicialState
      default:
            return state
    } 
}
export default presence