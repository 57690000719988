export default {
    titleState: "VACATIONS STATE",
    proceso: '',
    total: 1,
    allData: [],
    data: [],
    selectedEmployee: {},
    allPeriodVacation: [],
    allResumeVacation: [],
    allSelectedVacation: []
}

