export default {
    titleState: "PROCESS STATE",
    total: 1,
    payrollList: [],
    selectedPayroll: {},
    allData: [],
    data: [],
    resumeAccounting: []
}

