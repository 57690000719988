// Import Propios
import listInicialState from '../initialstates/list'
import { types } from '../../../types/types'

const auth = (state, action) => {
  console.log('action Lista', action.type)
    switch (action.type) {
        case types.listaDatos:
          return {
            ...state,
            allData: action.allData,
            proccess: action.proceso
          }

        case types.listaValores:
            return {
              ...state,
              data: action.data,
              total: action.total
            }
 
        case types.listaParametros:
          return {
            ...state,
            params: action.params
          }
      case types.listaLogout:
          return listInicialState
      
      default:
            return state
    }
}

export default auth