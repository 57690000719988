// Import Propios
import paramInicialState from '../initialstates/paramInicialState'
import { types } from '../../../types/types'


const params = (state, action) => {
  console.log('action Params', action.type)
    switch (action.type) {
        // Datos de la Empresa
        case types.parametrosDatosEmpresa:
          return {
            ...state,
            datosEmpresa: action.value,
            state: false
        }      

        // Datos de las Ubicaciones
        case types.parametrosUbicaciones:
          return {
            ...state,
            listaUbicaciones: action.value
            
        }      
        // Datos de las Departametos
        case types.parametrosDepartamentos:
          return {
            ...state,
            listaDepartamentos: action.value
        }  
        // Datos de las Ocupaciones
        case types.parametrosOcupaciones:
          return {
            ...state,
            listaOcupaciones: action.value
        }   
        // Datos de los Roles
        case types.parametrosRoles:
          return {
            ...state,
            listaRoles: action.value
        } 
        // Datos de los Estados
        case types.parametrosEstados:
          return {
            ...state,
            listaEstados: action.value
        } 
        // Datos de los Tipos de Identificacion
        case types.parametrosTiposId:
          return {
            ...state,
            listaTiposId: action.value
        } 
        
        // Datos de los Paises
        case types.parametrosPaises:
          return {
            ...state,
            listaPaises: action.value
        } 
        // Datos de los Estados Civiles
        case types.parametrosEstadosCiviles:
          return {
            ...state,
            listaEstadosCiviles: action.value
        } 
        // Datos de los Bancos
        case types.parametrosBancos:
          return {
            ...state,
            listaBancos: action.value
        } 
        // Datos de los Niveles de Escolaridad
        case types.parametrosEscolaridad:
          return {
            ...state,
            listaNivelesEscolaridad: action.value
        } 
        // Datos de las localizaciones
        case types.parametrosLocalizaciones:
          return {
            ...state,
            listaLocalizaciones: action.value
        } 
        // Datos de los Horarios
        case types.parametrosHorarios:
          return {
            ...state,
            listaHorarios: action.value
        } 
        // Datos de los tipos de egresos
       case types.parametrosEgresos:
       return {...state,
           listaEgresos: action.value
       } 
      case types.paramLogout:
          return paramInicialState
      
      default:
            return state
    }
}
export default params