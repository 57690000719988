// Import Propios
import staffInicialState from '../initialstates/staffInicialState'
import { types } from '../../../types/types'


const processes = (state, action) => {
  console.log('action Processes', action.type)
  console.log('action.value', action.value)
  
    switch (action.type) {
      // Logout de Acciones de Personal.
      case types.accionLogouts:
          return staffInicialState
      
      default:
            return state
    } 
}
export default processes