import mock from '../mock'

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'analyticsDash',
        isBookmarked: false,
        title: 'Tablero Analítico',
        icon: 'Home',
        link: '/dashboard/analytics'
      },
    
      {
        id: 2,
        target: 'payrollusers',
        isBookmarked: true,
        title: 'Usuarios de Planilla',
        icon: 'MessageSquare',
        link: '/payroll/administrator/payrollusers/list'
      },
      {
        id: 3,
        target: 'payrolllist',
        isBookmarked: true,
        title: 'Lista Planillas',
        icon: 'CheckSquare',
        link: '/payroll/parameters/payrollcalendar/list'
      },
      {
        id: 4,
        target: 'schedule',
        isBookmarked: true,
        title: 'Horarios',
        icon: 'Calendar',
        link: '/payroll/parameters/schedule/list'
      },
      {
        id: 5,
        target: 'employees',
        isBookmarked: false,
        title: 'Lista de Empleados',
        icon: 'List',
        link: '/payroll/personaldata/employees/list'
      },
      {
        id: 6,
        target: 'diaryregister',
        isBookmarked: false,
        title: 'Asistencia Diaria',
        icon: 'FileText',
        link: '/payroll/presence/diaryregister'
      },
      {
        id: 7,
        target: 'fileRegister',
        isBookmarked: false,
        title: 'Cargar Archivo de Asistencia',
        icon: 'Edit',
        link: '/payroll/presence/fileregister'
      },
      {
        id: 8,
        target: 'fileError',
        isBookmarked: false,
        title: 'Errores en Carga Archivo',
        icon: 'AlertTriangle',
        link: '/payroll/presence/fileerror'
      },
      {
        id: 9,
        target: 'clockRegister',
        isBookmarked: false,
        title: 'Aplicar Asistencia Archivo',
        icon: 'ShoppingCart',
        link: '/payroll/presence/clockregister'
      },
      {
        id: 10,
        target: 'extraHour',
        isBookmarked: false,
        title: 'Horas Extras',
        icon: 'Circle',
        link: '/payroll/presence/extrahours'
      },
      {
        id: 11,
        target: 'payrollReview',
        isBookmarked: false,
        title: 'Revisión de Planilla',
        icon: 'Heart',
        link: '/payroll/processes/payrollreview'
      },
      {
        id: 12,
        target: 'payrollcalculation',
        isBookmarked: false,
        title: 'Calcular Planilla',
        icon: 'CreditCard',
        link: '/payroll/processes/payrollcalculate'
      },
      {
        id: 13,
        target: 'paydocument',
        isBookmarked: false,
        title: 'Comprobantes de Pago',
        icon: 'User',
        link: '/payroll/postpayroll/paydocument'
      },
      {
        id: 14,
        target: 'accountingresume',
        isBookmarked: false,
        title: 'Resumen Contable',
        icon: 'User',
        link: '/payroll/postpayroll/accountingresume'
      },
      {
        id: 15,
        target: 'paymentresume',
        isBookmarked: false,
        title: 'Resumen de Pago',
        icon: 'LogIn',
        link: '/payroll/postpayroll/paymentResumen'
      },
      {
        id: 16,
        target: 'periods',
        isBookmarked: false,
        title: 'Periodos de Vacaciones',
        icon: 'LogIn',
        link: '/payroll/vacations/periods'
      }
    ]
  },
  {
    groupTitle: 'Files',
    searchLimit: 4,
    data: [
      {
        title: 'Passport Image',
        by: 'Oliver Queen',
        size: '52kb',
        file: require('@src/assets/images/icons/jpg.png').default
      },
      {
        title: 'Parenting Guide',
        by: 'Alfred Pennyworth',
        size: '2.3mb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Notes',
        by: 'Barry Allen',
        size: '30kb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Attendance',
        by: 'Walter White',
        size: '52mb',
        file: require('@src/assets/images/icons/xls.png').default
      }
    ]
  },
  {
    groupTitle: 'Contacts',
    searchLimit: 4,
    data: [
      {
        title: 'Mia Davis',
        email: 'miadavis@teleworm.us',
        img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
        date: '01/03/2020'
      },
      {
        title: 'Norris Carrière',
        email: 'NorrisCarriere@rhyta.com',
        img: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
        date: '07/03/2020'
      },
      {
        title: 'Charlotte Gordon',
        email: 'CharlotteGordon@jourrapide.com',
        img: require('@src/assets/images/portrait/small/avatar-s-26.jpg').default,
        date: '14/03/2020'
      },
      {
        title: 'Robert Nash',
        email: 'RobertNash@dayrep.com',
        img: require('@src/assets/images/portrait/small/avatar-s-25.jpg').default,
        date: '21/03/2020'
      }
    ]
  }
]

// GET Search Data
mock.onGet('/api/main-search/data').reply(() => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(() => {
  const bookmarks = searchArr[0].data.filter(item => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply(config => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find(item => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
