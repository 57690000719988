// Import Propios
import employeesInitialState from '../initialstates/employeesInicialState'
import { types } from '../../../types/types' 

const employees = (state, action) => {
    
    console.log('action employees', action.type)
    console.log('action ', action)
    switch (action.type) {
        case types.empleadosPlanilla:
          return {
            ...state,
            allData: action.allData,
            proccess: action.proceso
          }
        case types.empleadosLocalizaciones:
            return {
              ...state,
              allLocalization: action.allLocalization,
              proccess: action.proceso
            }
        case types.empleadosEntradaSalida:
              return {
                ...state,
                allEntry: action.allEntry,
                proccess: action.proceso
              }             
        case types.empleadosSalarios:
                return {
                  ...state,
                  allSalary: action.allSalary,
                  proccess: action.proceso
                }             
        case types.empleadosValores:
            return {
              ...state,
              data: action.data,
              total: action.total
            }
 
        case types.empleadosParametros:
          return {
            ...state,
            params: action.params
          }
      case  types.empleadoSeleccionado:
            return {
               ...state,
               selectedEmployee: action.data
            } 
      case  types.empleadoSeleLocalizacion:
            return {
               ...state,
               selectedEmployeeLocalization: action.dataLocalization
            } 
      case  types.empleadoSeleSalario:
            return {
                 ...state,
                 selectedEmployeeSalary: action.dataSalary
              } 
      case  types.empleadoSeleEntrada:
            return {
                 ...state,
                 selectedEmployeeEntry: action.dataEntry
            } 
      case  types.empleadoVacaciones:
              return {
                   ...state,
                   selectedEmployeeVacations: action.dataVacations
              } 
      case  types.empleadoGeneral:
              return {
                 ...state,
                 generalEmployee: action.generalData
              } 
      case  types.empleadoLocalizaciones:
              return {
                ...state,
                localizationsEmployee: action.localizationsData
              }   
      case  types.empleadoTrabajo:
              return {
                 ...state,
                 workEmployee: action.workData
              }
      case  types.empleadoIndicadorPago:
                return {
                   ...state,
                   paymentIndicator: action.indPago
              }
      case  types.empleadoSalario:
                return {
                   ...state,
                   salaryEmployee: action.salaryData
                }   
      case  types.empleadosLimpiaSeleccion:
                  return {
                     ...state,
                     selectedEmployee: {}, 
                     generalEmployee: {}, 
                     localizationsEmployee: {}, 
                     workEmployee: {}, 
                     salaryEmployee: {} 
                  }                                          
      case types.empleadosLogout:
          return employeesInitialState
      
      default:
            return state
    }
}

export default employees
