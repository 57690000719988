import { fetchSinToken} from '../../fetch'
const utilRequest = async (idPeticion, parameters) => { 
   // Ruta para subir y bajar archivos.
   const url = `${process.env.REACT_APP_API_URL}/${'util/'}`
   const method = 'GET'
   const {archivo, nom_archivo, carpeta} = parameters 
   
   switch (idPeticion) {
      case 1: // Trae Imagen
         const resp1 = await fetch(
                     url, {method, 
                       headers: 
                       {
                           "Content-type": "application/json",
                           nom_archivo, 
                           carpeta
                        }
                      })
         const body1 = await resp1.blob()  
         const imageObjectURL1 = URL.createObjectURL(body1)
         return imageObjectURL1
         break 
      case 2: // Envía la imagen 
         const {num_usuario, num_empresa, cod_usuario, num_empleado, indInserta, indModifica, usrEjecuta} = parameters
         const formData = new FormData()
         formData.append('archivo', archivo)
         formData.append('nom_archivo', nom_archivo)
         formData.append('carpeta', carpeta)
         formData.append('num_usuario', num_usuario)
         formData.append('num_empresa', num_empresa)
         formData.append('cod_usuario', cod_usuario)
         formData.append('num_empleado', num_empleado)
         formData.append('indInserta', indInserta)
         formData.append('indModifica', indModifica)
         formData.append('usrEjecuta', usrEjecuta)
         const enviarArchivo  = await fetch(url, {
                                         method: 'POST', body: formData
                                       })
         return enviarArchivo
         break 
     case 3:  // Enviar correo Recuperación de Contraseña.
       const resp3 = await fetchSinToken('util/email', parameters, "POST")
       const body3 = await resp3.json()
       return body3   
       break 
     /*  const resp = await fetchSinToken('util/email', {num_usuario, num_empresa, cod_usuario: codusuario, id_correo: 2, cod_correoDestino: email, ipuser}, 'POST')
      const body = await resp.json()
      console.log(` body ok ${body.ok} mensaje  ${body.esp_msg}`)
      if (body.ok) {
         // Limpia los States del formulario
         setCodUsuario('')
         setEmail('')
         setValErrors('')
         Swal.fire({
           icon: 'success',
           title: ` ${body.esp_msg}. ¿No encuentra el correo?. Búsquelo en la bandeja correos no deseados`,
           showConfirmButton: true
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: body.esp_msg,
          showConfirmButton: true
        })
      }*/
   }  
                   
}
export default utilRequest