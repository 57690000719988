import { fetchConToken} from '../../fetch'
const vacationRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Lista de Empleados de Vacaciones
         const resp1 = await fetchConToken("vacaciones/vacationemployee", parameters, "POST")
         const body1 = await resp1.json()
         return body1
         break   
      case 2:  // Datos de Periodos y resumen de Vacaciones
         const resp2 = await fetchConToken("vacaciones/vacationdata", parameters, "POST")
         const body2 = await resp2.json()
         return body2
         break                
      }  
                   
   }
   export default vacationRequest