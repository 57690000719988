import { fetchConToken} from '../../fetch'
const paramRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Parámetros de las ubicaciones.
         const resp1 = await fetchConToken("parametros/ubicacionlista", parameters, "POST")
         const body1 = await resp1.json()
         return body1
         break     
      case 2:  // Lista de las departamentos.
         const resp2 = await fetchConToken("parametros/departamentolista", parameters, "POST")
         const body2 = await resp2.json()
         return body2
         break 
      case 3:  // Lista de las ocupaciones.
         const resp3 = await fetchConToken("parametros/ocupacionlista", parameters, "POST")
         const body3 = await resp3.json()
         return body3
         break  
      case 4: // Lista de los roles
         const resp4 = await fetchConToken("parametros/rollista", parameters, "POST")
         const body4 = await resp4.json()
         return body4
         break   
      case 5: // Lista de los estados
         const resp5 = await fetchConToken("parametros/estadolista", parameters, "POST")
         const body5 = await resp5.json()
         return body5
         break  
      case 6: // Lista de los estados
         const resp6 = await fetchConToken("parametros/tipoidlista", parameters, "POST")
         const body6 = await resp6.json()
         return body6
         break   
      case 7: // Obtener Datos Paises
         const resp7 = await fetchConToken("parametros/paislista", parameters, "POST")
         const body7 = await resp7.json()
         return body7
         break  
      case 8: // Obtener la Lista de Estados Civiles
         const resp8 = await fetchConToken("parametros/estadocivilista", parameters, "POST")
         const body8 = await resp8.json()
         return body8
         break  
      case 9: // Carga los parámetros de los Bancos.
         const resp9 = await fetchConToken("parametros/bancolista", parameters, "POST")
         const body9 = await resp9.json()
         return body9
         break  
      case 10: // Carga los parámetros de los Niveles de Escolaridad.
         const resp10 = await fetchConToken("parametros/escolaridadlista", parameters, "POST")
         const body10 = await resp10.json()
         return body10
         break  
      case 11:  // Obtener la Lista de Localizaciones
         const resp11 = await fetchConToken("parametros/localizacionlista", parameters, "POST")
         const body11 = await resp11.json()
         return body11
         break  
      case 12: // Lista de los horarios
         const resp12 = await fetchConToken("parametros/horariolista", parameters, "POST")
         const body12 = await resp12.json()
         return body12
         break  
      case 13:  // Datos de la empresa
         const resp13 = await fetchConToken("parametros/datosempresa", parameters, "POST")
         const body13 = await resp13.json()
         return body13   
         break  
      case 14:  // Datos analíticos de la planilla
         const resp14 = await fetchConToken("parametros/analyticpayroll", parameters, "POST")
         const body14 = await resp14.json()
         return body14   
         break  
      case 15:  // Datos analíticos de asistencia lineal
         const resp15 = await fetchConToken("parametros/analyticlinealassitence", parameters, "POST")
         const body15 = await resp15.json()
         return body15   
         break 
      case 16:  // Datos de la lista de planillas del año
         const resp16 = await fetchConToken("paramPlanilla/planillalist", parameters, "POST")
         const body16 = await resp16.json()
         return body16   
         break 
      case 17:  //Datos de la lista de horarios
         const resp17 = await fetchConToken("paramPlanilla/schedulelist", parameters, "POST")
         const body17 = await resp17.json()
         return body17   
         break 
      }       
   }
   export default paramRequest
