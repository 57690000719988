// Import Propios
import authInicialState from '../initialstates/authInicialState'
import { types } from '../../../types/types'

const auth = (state, action) => {
  console.log('action Autenticación', action.type)
  console.log('action value', action.value)
    switch (action.type) {
       // Datos del Usuario
       case types.authUserData:
          return {
            ...state,
            checking: false,
            userData: action.value            
        }     
       // Menú del Sistema
       case types.menuSistema:
          return {
            ...state,
            suggestions: action.value            
        }  
       // BookMarks
       case types.menuFavorito:
          return {
            ...state,
            bookMarks: action.value            
        }  
       // Logout
      case types.authLogout:
          return authInicialState
      
      default:
            return state
    }
}

export default auth