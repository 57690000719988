import { fetchConToken} from '../../fetch'
const userRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Lista de Empleados
         const resp1 = await fetchConToken("usuarios/new", parameters, "POST")
        const body1 = await resp1.json()
         return body1
         break 
      case 2:  // Lista de Empleados
         const resp2 = await fetchConToken("usuarios/userlist", parameters, "POST")
         const body2 = await resp2.json()
         return body2
         break                    
      }  
                   
   }
   export default userRequest