import { fetchConToken} from '../../fetch'
const employeeRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Lista de Empleados
         const resp1 = await fetchConToken("empleados/employeeslist", parameters, "POST")
         const body1 = await resp1.json()
         return body1
         break 
      case 2: // Obtener Datos Padron
         const resp2 = await fetchConToken("empleados/padrondata", parameters, "POST")
         const body2 = await resp2.json()
         return body2
         break 
      case 3: // Lista Localizaciones
         const resp3 = await fetchConToken("empleados/localizationlist", parameters, "POST")
         const body3 = await resp3.json()
         return body3
         break 
      case 4: // Lista Entradas y salidas del empleado
         const resp4 = await fetchConToken("empleados/entrylist", parameters, "POST")
         const body4 = await resp4.json()
         return body4
         break         
      case 5: // Lista de Salarios del Empleado
         const resp5 = await fetchConToken("empleados/salarieslist", parameters, "POST")
         const body5 = await resp5.json()   
         return body5
         break    
      case 6: // Obtiene la lista de Egresos
         const resp6 = await fetchConToken("parametros/exitlist", parameters, "POST")
         const body6 = await resp6.json()   
         return body6
         break       
      case 7: // Agrega empleado
         const resp7 = await fetchConToken("empleados/new", parameters, "POST")
         const body7 = await resp7.json()
         return body7
         break       
      case 8: // Activar un empleado
         const resp8 = await fetchConToken("empleados/activate", parameters, "POST")
         const body8 = await resp8.json()
         return body8
         break     
      case 9: // agrega un salario
         const resp9 = await fetchConToken("empleados/newSalary", parameters, "POST")
         const body9 = await resp9.json()
         return body9
         break   
      case 10: // Actualiza la Designación del trabajo
         const resp10 = await fetchConToken("empleados/updatehorariubicacion", parameters, "POST")
         const body10 = await resp10.json()
         return body10            
         break           
      case 11: // Agrega la Localización
         const resp11 = await fetchConToken("empleados/newLocalization", parameters, "POST")
         const body11 = await resp11.json()
         return body11
         break 
      case 12: // Elimina la Localización
         const resp12 = await fetchConToken("empleados/deletelocalization", parameters, "DELETE")
         const body12 = await resp12.json()
         return body12
         break  
      case 13: // Modificar la Localización
         const resp13 = await fetchConToken("empleados/modifylocalization", parameters, "PUT")
         const body13 = await resp13.json()
         return body13
         break  
      case 14: // Modifica Empleado
         const resp14 = await fetchConToken("empleados/update", parameters, "PUT")
         const body14 = await resp14.json()
         return body14
         break 
      case 15: // Modifica Ubicación - Horario
         const resp15 = await fetchConToken("empleados/updatehorarioubicacion", parameters, "PUT")
         const body15 = await resp15.json()
         return body15
         break 
      case 16: // Llama la inactivación del empleado
         const resp16 = await fetchConToken("empleados/inactivate", parameters, "POST")
         const body16 = await resp16.json()
         return body16
         break        
      case 17: // Llama a la proyección del empleado activado
         const resp17 = await fetchConToken("empleados/employeeprojection", parameters, "POST")
         const body17 = await resp17.json()
         return body17
         break                                                                   
      }  
                   
   }
   export default employeeRequest