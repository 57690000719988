import { fetchConToken} from '../../fetch'
const processRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Lista de Planillas Activas
         const resp1 = await fetchConToken("procesoPlanilla/payrolllist", parameters, "POST")
         const body1 = await resp1.json()
         return body1
         break
      case 2:  // Proyecta Planilla
         const resp2 = await fetchConToken("procesoPlanilla/projectpayroll", parameters, "PUT")
         const body2 = await resp2.json()
         return body2
         break
      case 3:  // Traer Empleados Planilla
         const resp3 = await fetchConToken("procesoPlanilla/payrollemployeeslist", parameters, "POST")
         const body3 = await resp3.json()
         return body3
         break
      case 4:  // Llama las planilla que ya al fecha final es menor a hoy
         const resp4 = await fetchConToken("procesoPlanilla/payrollcalculate", parameters, "POST")
         const body4 = await resp4.json()
         return body4
         break           
      case 5:  // Aplicar Planilla
         const resp5 = await fetchConToken("procesoPlanilla/calculatePayroll", parameters, "PUT")
         const body5 = await resp5.json()
         return body5
         break
      case 6:  // Calcular Resumen de Planilla.
         const resp6 = await fetchConToken("procesoPlanilla/accountingresume", parameters, "POST")
         const body6 = await resp6.json()
         return body6
         break  
      case 7:  // Agregar Bitácora de envío de Comprobante de Pago.
         const resp7 = await fetchConToken("procesoPlanilla/binnacledocument", parameters, "PUT")
         const body7 = await resp7.json()
         return body7
         break     
      case 8:  // Obtien el resumen de pago de la planilla.
         const resp8 = await fetchConToken("procesoPlanilla/paymentresume", parameters, "POST")
         const body8 = await resp8.json()
         return body8
         break                      
      }  
                   
   }
   export default processRequest