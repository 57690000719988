// Import Propios
import postPaidInicialState from '../initialstates/postPaidInicialState'
import { types } from '../../../types/types'


const post = (state, action) => {
  console.log('action Post', action.type)
  console.log('action Post Data', action.value)
    switch (action.type) {
        // Lista de Planillas pagadas
        case types.postListaPlanillaPagada:
          return {
            ...state,
            totalPayrollList: action.total,
            allDataPayrollList: action.value
          } 
        case types.postPlanillaValores:
            return {
              ...state,
              dataPayrollList: action.data,
              totalPayrollList: action.total
            }  
        case types.postPlanillaSeleccionada:
            return {
              ...state,
              selectedPayroll: action.value
            }     
        case types.postListaEmpleadoPagado:
            return {
              ...state,
              totalEmployeeList: action.total,
              allDataEmployeeList: action.value
            } 
        case types.postEmpleadoValores:
            return {
              ...state,
              dataEmployeeList: action.data,
              totalEmployeeList: action.total
            }  
         case types.postEmpleadoSeleccionado:
            return {
               ...state,
               selectedEmployee: action.value
            }  
        case types.postListacomprobantePago:
              return {
                ...state,
                allDataDocumentList: action.data
              }     
        case types.postDatacomprobantePago:
              return {
                  ...state,
                  DataDocumentList: action.data
                }          
         case types.postLogouts:
            return postPaidInicialState
         default:
            return state
    } 
}
export default post