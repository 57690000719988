export default {
   titleState: "PARAM STATE",
   listaUbicaciones: [],
   listaDepartamentos: [],
   listaOcupaciones: [],
   listaRoles: [],
   listaEstados: [],
   listaTiposId: [],
   listaPaises: [],
   listaEstadosCiviles: [],
   listaBancos: [],
   listaNivelesEscolaridad: [],
   listaLocalizaciones: [],
   listaHorarios: [],
   listaEgresos: []
}
