import React, { createContext, useReducer, useState } from "react"
import Swal from 'sweetalert2'
//
import authInitialState from "./initialstates/authInicialState"

import employeesInitialState from './initialstates/employeesInicialState'
import listInicialState from './initialstates/list'
import paramInicialState from './initialstates/paramInicialState'
import presenceInicialState from './initialstates/presenceInicialState'
import processInicialState from './initialstates/processInicialState'
import postPaidInicialState from './initialstates/postPaidInicialState'
import staffInicialState from './initialstates/staffInicialState'
import usersInitialState from './initialstates/usersInicialState'
import vacationsInitialState from './initialstates/vacationsInicialState'

// Reducers
import auth from './reducers/auth'
import employees from './reducers/employees'
import list from './reducers/list'
import params from './reducers/params'
import presence from './reducers/presence'
import processes from './reducers/processes'
import post from './reducers/post'
import staff from './reducers/staff'
import users from './reducers/users'
import vacations from './reducers/vacations'
// Actions
import authRequest from './actions/authRequest'
import userRequest from './actions/userRequest'
import employeeRequest from './actions/employeeRequest'
import paramRequest from './actions/paramRequest'
import utilRequest from './actions/utilRequest'
import presenceRequest from './actions/presenceRequest'
import processRequest from './actions/processRequest'
import postPaidRequest from './actions/postPaidRequest'
import staffRequest from './actions/staffActionsRequest'
import vacationRequest from './actions/vacationRequest'

export const GlobalContext = createContext({})
export const GlobalProvider = ({children}) => {
    const [authState, authDispath] = useReducer(auth, authInitialState)
    const [employeesState, employeesDispatch] = useReducer(employees, employeesInitialState)
    const [listState, listDispatch] = useReducer(list, listInicialState)
    const [parameters, setParameters] = useState({})
    const [paramsState, paramsDispatch] = useReducer(params, paramInicialState)
    const [peticion, setPeticion] = useState(0)
    const [presenceState, presenceDispatch] = useReducer(presence, presenceInicialState)
    const [processState, processDispatch] = useReducer(processes, processInicialState)
    const [postState, postDispatch] = useReducer(post, postPaidInicialState)
    const [staffState, staffDispatch] = useReducer(staff, staffInicialState)
    const [status, setStatus] = useState(0)
    const [usuarios, setUsuarios] = useState({})
    const [userState, userDispatch] = useReducer(users, usersInitialState)
    const [vacationState, vacationDispatch] = useReducer(vacations, vacationsInitialState
      )
    
    return (
       <GlobalContext.Provider value={{authState, 
                                       authRequest,
                                       authDispath, 
                                       utilRequest, 
                                       employeesState, 
                                       employeeRequest, 
                                       employeesDispatch,
                                       listState,
                                       listDispatch,
                                       paramsState,
                                       paramRequest, 
                                       paramsDispatch,
                                       setParameters,
                                       setPeticion, 
                                       setStatus,
                                       setUsuarios,
                                       status,
                                       parameters, 
                                       peticion,
                                       presenceState, 
                                       presenceRequest, 
                                       presenceDispatch,
                                       processState, 
                                       processRequest, 
                                       processDispatch, 
                                       postDispatch, 
                                       postPaidRequest,
                                       postState, 
                                       staffState, 
                                       staffRequest, 
                                       staffDispatch,
                                       userState,
                                       userRequest, 
                                       userDispatch,
                                       usuarios,
                                       vacationState,
                                       vacationRequest, 
                                       vacationDispatch
                                       }} >
          {children}
       </GlobalContext.Provider>
      )
}
