export const types = {

   // Autenticación del Usuario
   authUserData:  '[AUTH] Datos del Usuario Autenticado',
   menuSistema:   '[AUTH] Menú del Sistema',
   menuFavorito:  '[AUTH] Menú Favorito del Usuario (BookMarks)',
   authLogout:    '[AUTH] Logout Autenticación',


   listaDatos:      '[LISTADODATOS] Carga todos los registros a la lista',
   listaValores:    '[LISTADODATOS] Carga valores a la lista listas',
   listaParametros: '[LISTADODATOS] Carga parámetros a las listas',
   listaLogout:     '[LISTADODATOS] Logout de Lista de Datos',

   // Parámetros Generales la Planilla
   parametrosDatosEmpresa:  '[PARAMETROSGENERALES] Asigna los datos de la empresa',
   parametrosUbicaciones:   '[PARAMETROSGENERALES] Asigna la Lista de Ubicaciones',
   parametrosDepartamentos: '[PARAMETROSGENERALES] Asigna la Lista de Departamentos',
   parametrosOcupaciones:   '[PARAMETROSGENERALES] Asigna la Lista de Ocupaciones',
   parametrosRoles:         '[PARAMETROSGENERALES] Asigna la Lista de Roles',
   parametrosEstados:       '[PARAMETROSGENERALES] Asigna la Lista de Estados',
   parametrosTiposId:       '[PARAMETROSGENERALES] Asigna la Lista de los Tipos de Identificación',
   parametrosPaises:        '[PARAMETROSGENERALES] Asigna la Lista de los Paises',
   parametrosEstadosCiviles:'[PARAMETROSGENERALES] Asigna la Lista de los Estados Civiles',
   parametrosBancos:        '[PARAMETROSGENERALES] Asigna la Lista de los Bancos',
   parametrosEscolaridad:   '[PARAMETROSGENERALES] Asigna la Lista de la Escolaridad',
   parametrosLocalizaciones:'[PARAMETROSGENERALES] Asigna la Lista de las Localizaciones',
   parametrosHorarios:      '[PARAMETROSGENERALES] Asigna la Lista de los Horarios',
   parametrosEgresos:       '[PARAMETROSGENERALES] Asigna la Lista de los Egresos',
   paramLogout:             '[PARAMETROSGENERALES] Logout Parámetros',
   
      // Usuarios de la Planilla
   planillaUsuarios:            '[PLANILLA] Obtiene la Lista de Usuarios', 
   planillaDatos:               '[PLANILLA] Datos de Usuarios por página',
   planillaUsuarioSeleccionado: '[PLANILLA] Datos del Usuario Seleccionado',
   usersLogout:                 '[PLANILLA] Logout de Lista de Usuarios',
  
  // Empleados de la Planilla
  empleadosPlanilla:       '[EMPLEADOS] Carga todos los registros de empleados', 
  empleadosLocalizaciones: '[EMPLEADOS] Carga todos los registros de las Localizaciones',
  empleadosEntradaSalida:  '[EMPLEADOS] Carga todas las entradas y salidas de los empleados',
  empleadosSalarios:       '[EMPLEADOS] Carga todas los salarios de los empleados',
  empleadosValores:        '[EMPLEADOS] Datos de Empleados por página',
  empleadosParametros:     '[EMPLEADOS] Carga parámetros a las los empleados',
  empleadoSeleccionado:    '[EMPLEADOS] Datos del Empleado Seleccionado',
  empleadoSeleLocalizacion:'[EMPLEADOS] Localizaciones del Empleado Seleccionado',
  empleadoSeleSalario:     '[EMPLEADOS] Salarios del Empleado Seleccionado',
  empleadoSeleEntrada:     '[EMPLEADOS] Entradas del Empleado Seleccionado',
  empleadoVacaciones:      '[EMPLEADOS] Periodos de Vacaciones Empleado Seleccionado',
  empleadoGeneral:         '[EMPLEADOS] Datos Generales del Empleado',
  empleadoLocalizaciones:  '[EMPLEADOS] Datos de las Localizaciones del Empleado',
  empleadoTrabajo:         '[EMPLEADOS] Datos de la asignación del trabajo',
  empleadoIndicadorPago:   '[EMPLEADOS] Periodicidad de Pago del Empleado',
  empleadoSalario:         '[EMPLEADOS] Datos del Salario del empleado',
  empleadosLimpiaSeleccion:'[EMPLEADOS] Limpia los Datos del Empleado Seleccionado',
  empleadosLogout:         '[EMPLEADOS] Logout de Lista de Empleados',
  empleadosCheckingFinish: '[EMPLEADOS] Finish checking lista Empleados',

   // Parametros de Planilla 
   parametrosListaSemanal:    '[PARAMETROSPLANILLA] Asigna la Lista de Planillas Semanales',
   parametrosListaBisemanal:  '[PARAMETROSPLANILLA] Asigna la Lista de Planillas Bisemanales',
   parametrosListaQuincenal:  '[PARAMETROSPLANILLA] Asigna la Lista de Planillas Quincenales',
   parametrosListaMensual:    '[PARAMETROSPLANILLA] Asigna la Lista de Planillas Mensuales',
   parametrosPlanillaEstados: '[PARAMETROSPLANILLA] Asigna la Lista de Estados de la Planilla',
   paramPlanillaLogout:       '[PARAMETROSPLANILLA] Logout de Lista de Parámetros de Planilla',

   // Procesos de la Planilla
   procesoListaPlanilla:        '[PROCESOS] Obtiene la lista de Planillas según el estado', 
   procesoPlanillaSeleccionada: '[PROCESOS] Datos de la Planilla Seleccionada',
   procesoEmpleadosPlanilla:    '[PROCESOS] Obtiene la lista de Empleados por Planilla',
   procesoValores:              '[PROCESOS] Datos de la Página de Empleados por Planilla',
   procesoResumenContable:      '[PROCESOS] Carga el proceso del Resumen Contable',
   processLogouts:              '[PROCESOS] Logout de los procesos',
   // Asistencia = Presence
   asistenciaListaPlanilla:        '[ASISTENCIA] Obtiene la lista de Planillas Proyectadas', 
   asistenciaEmpleadosPlanilla:    '[ASISTENCIA] Obtiene la lista de Empleados por Planilla',
   asistenciaPlanillaSeleccionada: '[ASISTENCIA] Datos de la Planilla Seleccionada',
   asistenciaFechaSeleccionada:    '[ASISTENCIA] Fecha Seleccionada',
   asistenciaEmpleadoSeleccionado: '[ASISTENCIA] Empleado Seleccionado', 
   asistenciaHoraEntrada:          '[ASISTENCIA] Hora de Entrada',
   asistenciaHoraSalida:           '[ASISTENCIA] Hora de Salida',
   asistenciaLista:                '[ASISTENCIA] Lista de Asistencia',
   asistenciaProyeccionLista:      '[ASISTENCIA] Lista de Proyección de Asistencia',
   asistenciaRangosFecha:          '[ASISTENCIA] Lista de Rangos de Fechas de Incapacidad y Vacaciones',
   asistenciaDiasVacaciones:       '[ASISTENCIA] Días de Vacaciones permitidos',
   asistenciaPlanillasReloj:       '[ASISTENCIA] Planillas con marcas de Reloj',
   asistenciaEmpleadosReloj:       '[ASISTENCIA] Empleados con marcas de Reloj',
   asistenciaStatusSeleccionado:   '[ASISTENCIA] Indica los status seleccionados',
   asistenciaTotal:                '[ASISTENCIA] Trae todos los datos de Asistencia de planillas proyectadas',
   asistenciaEmpleado:             '[ASISTENCIA] Asistencia del empleado seleccionado',
   asistenciaEmpleadoDia:          '[ASISTENCIA] Asistencia del empleado seleccionado por fecha',
   asistenciaFiltroNombre:         '[ASISTENCIA] Nombre del empleado filtrado',
   asistenciaHorasExtras:          '[ASISTENCIA] Agrega las horas extras',
   asistenciaLogouts:              '[ASISTENCIA] Logout de la Asistencia',

   postListaPlanillaPagada:        '[POSTPLANILLA] Obtiene la lista de Planillas Pagadas',
   postPlanillaValores:            '[POSTPLANILLA] Datos de la Página de Planilla',
   postPlanillaSeleccionada:       '[POSTPLANILLA] Datos de la Planilla Seleccionada',
   postListaEmpleadoPagado:        '[POSTPLANILLA] Obtiene la lista de Empleados Pagados',
   postEmpleadoValores:            '[POSTPLANILLA] Datos de la Página de Empleados',
   postEmpleadoSeleccionado:       '[POSTPLANILLA] Datos del Empleado Seleccionado',
   postListacomprobantePago:       '[POSTPLANILLA] Obtiene la lista de comprobantes de Pago',
   postDatacomprobantePago:        '[POSTPLANILLA] Obtiene los comprobantes de pago del empleado',
   postLogouts:                    '[POSTPLANILLA] Logout de Post Pago Planilla',

  // Empleados de la Planilla
  vacacionesEmpleados:            '[VACACIONES] Carga todos los registros de empleados', 
  vacacionesValores:              '[VACACIONES] Datos de Empleados por página',
  vacacionesEmpleadoSeleccionado: '[VACACIONES] Datos del Empleado Seleccionado de Vacaciones',
  vacacionesDatos:                '[VACACIONES] Datos de Todos los Periodos y resumen de Vacaciones',
  vacacionesPeriodoSeleccionado:  '[VACACIONES] Datos de los Periodos del Empleado Seleccionado',
  vacacacionesLogouts:            '[VACACIONES] Logout de Vacaciones',

   accionLogouts:                  '[ACCIONES] Logout de las Acciones de Personal'
 }
 