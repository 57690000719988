// Import Propios
import vacationsInitialState from '../initialstates/vacationsInicialState'
import { types } from '../../../types/types' 

const vacations = (state, action) => {
    
    console.log('action vacations', action.type)
    console.log('action ', action)
    switch (action.type) {
        case types.vacacionesEmpleados:
          return {
            ...state,
            allData: action.allData,
            proccess: action.proceso
          }          
         
        case types.vacacionesValores:
            return {
              ...state,
              data: action.data,
              total: action.total
            }
      case  types.vacacionesEmpleadoSeleccionado:
            return {
               ...state,
               selectedEmployee: action.data
            } 
      case types.vacacionesDatos:
              return {
                ...state,
                allPeriodVacation: action.dataperiodo,
                allResumeVacation: action.dataresumen
              }
      case  types.vacacionesPeriodoSeleccionado:
              return {
                   ...state,
                   allSelectedVacation: action.data
              }                                        
      case types.vacacacionesLogouts:
          return vacationsInitialState
      
      default:
            return state
    }
}

export default vacations
