const baseUrl = process.env.REACT_APP_API_URL

const fetchSinToken = (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`
  if (method === "GET") {
     try {
       return fetch(url)
    } catch (error) {     
        console.log(`error GET sin token ${error}`)
    }
  } else {
      try {
           return fetch(
               url, {method,
                    headers: {
                              "Content-type": "application/json"
                             },
                    body: JSON.stringify(data)
                   }
           )
      } catch (error) {
        console.log(`error no GET sin Token ${error}`)
      }
  }
}

const fetchConToken = (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`
  const token = localStorage.getItem("token") || ""
  const datosconexion = localStorage.getItem("userData") || ""
  const {num_empresa, num_usuario, cod_usuario } = datosconexion
  const num_usuariotoken = num_empresa
  const num_empresatoken = num_usuario
  const cod_usuariotoken = cod_usuario
  if (method === "GET") {
    try {
      return fetch(url, {
        method,
        headers: {
          "Content-type": "application/json",
          "x-token": token,
          num_usuariotoken,
          num_empresatoken,
          cod_usuariotoken
        }
      })


    } catch (error) {
        console.log(`error GET ${error}`)
    }
  } else {
      try {
        return fetch(url, {
            method,
            headers: {
              "Content-type": "application/json",
              "x-token": token,
              num_usuariotoken,
              num_empresatoken,
              cod_usuariotoken
            },
            body: JSON.stringify(data)
          })
      } catch (error) {
          console.log(`error no GET ${error}`)
      }
  }
}
export { fetchSinToken, fetchConToken } 
