// Import Propios
import processesInicialState from '../initialstates/processInicialState'
import { types } from '../../../types/types'


const processes = (state, action) => {
  console.log('action Processes', action.type)
  console.log('action.value', action.value)
  
    switch (action.type) {
        // Lista de Planillas Activas
        case types.procesoListaPlanilla:
          return {
            ...state,
            payrollList: action.value,
            total: action.total
          }    
        case types.procesoPlanillaSeleccionada:
          return {
            ...state,
            selectedPayroll: action.value
          }   

        case types.procesoEmpleadosPlanilla:
              return {
                ...state,
                allData: action.value
          }
        case types.procesoValores:
            return {
              ...state,
              data: action.data,
              total: action.total
            }
        case types.procesoResumenContable:
              return {
                ...state,
                resumeAccounting: action.value
              }
            
       case types.processLogouts:
           return processesInicialState
      
      default:
            return state
    } 
}
export default processes