// Import Propios
import usersInitialState from '../initialstates/usersInicialState'
import { types } from '../../../types/types'

const users = (state, action) => {
    console.log('action User', action.type)
    switch (action.type) {
       case  types.planillaUsuarios:
          return {
              ...state,
              allData: action.value
          }
       case  types.planillaDatos:
          return {
               ...state,
               data: action.data,
               total: action.totalPages,
               params: action.params
            }
       case  types.planillaUsuarioSeleccionado:
          return {
                ...state,
                selectedUser: action.data
          }      
      case types.usersLogout:
          return usersInitialState
      
      default:
            return state
    }
}

export default users
