import { fetchConToken} from '../../fetch'
const postPaidRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Trae la lista de Planillas Pagadas
         const resp1 = await fetchConToken("postPlanilla/paidpayroll", parameters, "POST")
         const body1 = await resp1.json()
         return body1
         break
      case 2:  // Trae los empleados con pago de los últimos 3 meses
         const resp2 = await fetchConToken("postPlanilla/employeepaid3months", parameters, "POST")
         const body2 = await resp2.json()
         return body2
         break
      case 3:  // Trae los comprobantes de  pago de los últimos 3 meses
         const resp3 = await fetchConToken("postPlanilla/paiddocument3months", parameters, "POST")
         const body3 = await resp3.json()
         return body3
         break 
      case 4:  // Trae los comprobantes de  pago para un empleado y planilla específica.
         const resp4 = await fetchConToken("postPlanilla/paiddocumentEmployee", parameters, "POST")
         const body4 = await resp4.json()
         return body4
         break                                                              
      case 5:  // Envía el correo con el comprobante de Pago
         const resp5 = await fetchConToken("util/emailpaiddocument", parameters, "POST")
         const body5 = await resp5.json()
         return body5
         break                                                              
      }    
   }
   export default postPaidRequest