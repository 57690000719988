import { fetchConToken} from '../../fetch'
const presenceRequest = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Traer Asistencia de empleado por planilla
         const resp1 = await fetchConToken("asistencia/presencelist", parameters, "POST")
         const body1 = await resp1.json()
         return body1
         break
      case 2:  // Traer la Proyección de Asistencia del Empleado
         const resp2 = await fetchConToken("asistencia/projectionlist", parameters, "POST")
         const body2 = await resp2.json()
         return body2
         break
      case 3:  // Ingresa el Registro Diario
         const resp3 = await fetchConToken("asistencia/diaryAsistence", parameters, "PUT")
         const body3 = await resp3.json()
         return body3
         break
      case 4:  // Traer Asistencia de empleado por rango fechas
         const resp4 = await fetchConToken("asistencia/presencelistdates", parameters, "POST")
         const body4 = await resp4.json()
         return body4
         break   
      case 5:  // Traer los rangos de fechas de Vacaciones y de Incapacidades
         const resp5 = await fetchConToken("asistencia/rangeslist", parameters, "POST")
         const body5 = await resp5.json()
         return body5
         break        
      case 6:  // Traer los días de vacaciones disponibles.
         const resp6 = await fetchConToken("asistencia/vacationdays", parameters, "POST")
         const body6 = await resp6.json()
         return body6
         break   
      case 7:  // Elimina el día Ausente
         const resp7 = await fetchConToken("asistencia/deleteabsentday", parameters, "DELETE")
         const body7 = await resp7.json()
         return body7
         break 
      case 8:  // Agrega el archivo de texto a la Base de Datos
         const resp8 = await fetchConToken("asistencia/fileasistence", parameters, "PUT")
         const body8 = await resp8.json()
         return body8
         break      
      case 9:  // Llama al proceso que lee el CLOB enviado
         const resp9 = await fetchConToken("asistencia/readfile", parameters, "PUT")
         const body9 = await resp9.json()
         return body9
         break  
      case 10:  // Llama las planillas que tienen lectura de Reloj
         const resp10 = await fetchConToken("asistencia/clockpayroll", parameters, "POST")
         const body10 = await resp10.json()
         return body10
         break                                    
      case 11:  // Llama los empleados que tienen lectura de Reloj
         const resp11 = await fetchConToken("asistencia/clockmarks", parameters, "POST")
         const body11 = await resp11.json()
         return body11
         break   
      case 12:  // Llama los empleados que tienen lectura de Reloj
         const resp12 = await fetchConToken("asistencia/diaryasistenceclock", parameters, "PUT")
         const body12 = await resp12.json()
         return body12
         break    
      case 13:  // Llama las planillas que tienen horas extras
         const resp13 = await fetchConToken("asistencia/extrahourpayroll", parameters, "POST")
         const body13 = await resp13.json()
         return body13
         break  
      case 14:  // Llama los empleados que tienen marcas de reloj
         const resp14 = await fetchConToken("asistencia/clockmarks", parameters, "POST")
         const body14 = await resp14.json()
         return body14
         break 
      case 15:  // Llama los archivos cargados
         const resp15 = await fetchConToken("asistencia/filesclock", parameters, "POST")
         const body15 = await resp15.json()
         return body15
         break 
      case 16:  // Llama los empleados con asistencia del reloj
         const resp16 = await fetchConToken("asistencia/recordsclock", parameters, "POST")
         const body16 = await resp16.json()
         return body16
         break   
      case 17:  // Llama los empleados con horas extras
         const resp17 = await fetchConToken("asistencia/extrahours", parameters, "POST")
         const body17 = await resp17.json()
         return body17
         break  
      case 18:  // Llama al proceso de actualizar horas extras
         const resp18 = await fetchConToken("asistencia/updateextrahour", parameters, "PUT")
         const body18 = await resp18.json()
         return body18
         break    
      case 19:  // Llama al proceso de actualizar horas extras
         const resp19 = await fetchConToken("asistencia/asistenceextrahours", parameters, "PUT")
         const body19 = await resp19.json()
         return body19
         break  
      case 20:  // Llama las planillas proyectadas
         const resp20 = await fetchConToken("asistencia/payrollprojection", parameters, "POST")
         const body20 = await resp20.json()
         return body20
         break   
      case 21:  // Llama los empleados que están en las planillas proyectadas
         const resp21 = await fetchConToken("asistencia/payrollemployeesprojection", parameters, "POST")
         const body21 = await resp21.json()
         return body21
         break 
      case 22:  // Llama los empleados que están en las planillas proyectadas
         const resp22 = await fetchConToken("asistencia/employeesstatus", parameters, "POST")
         const body22 = await resp22.json()
         return body22
         break    
      case 23:  // Llama la asistencia diaria de los empleados que están en las planillas proyectadas
         const resp23 = await fetchConToken("asistencia/employeesdiarystatus", parameters, "POST")
         const body23 = await resp23.json()
         return body23
         break
      case 24:  // Actualiza a revisado la asistencia de los empleados seleccionados.
         const resp24 = await fetchConToken("asistencia/updatereview", parameters, "PUT")
         const body24 = await resp24.json()
         return body24
         break                                                         
      }      
   }
   export default presenceRequest