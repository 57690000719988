import { fetchConToken} from '../../fetch'
const requestStaffActions = async (idPeticion, parameters) => {  
   switch (idPeticion) {
      case 1:  // Ingresa los días de Incapacidad
         const resp1 = await fetchConToken("acciones/disabilityDays", parameters, "PUT")
         const body1 = await resp1.json()
         return body1
         break   
      case 2:  // Ingresa los días de Vacaciones
         const resp2 = await fetchConToken("acciones/vacationDays", parameters, "PUT")
         const body2 = await resp2.json()
         return body2
         break       
      }   
   }
   export default requestStaffActions